<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import ProductCategory from "../../models/ProductCategory";
    import VendorSelect from "./VendorSelect";
    import IconPicker from "../../components/IconPicker";

    export default {
        name: "BasicsForm",


        components: {
            VendorSelect,
            IconPicker
        },


        props: {
            model: {
                type: ProductCategory,
                default: () => new ProductCategory()
            }
        },


        data() {
            return {
                isEdit: this.model.id,
                isChanged: false
            }
        },


        methods: {
            async save() {
                try {
                    await this.model.save();
                    let message = this.isEdit ? `${this.model.name} успешно обновлен` : `${this.model.name} успешно создан`;
                    this.$message.success(message);
                } catch (e) {
                    if (this.model.fatal) {
                        this.$message.error("Ошибка запроса");
                    }
                    throw e;
                }
            },
            redirectToCard() {
                this.$router.push({
                    name: 'category-basic',
                    params: {
                        id: this.model.id
                    }
                });
            },
            onSaveCommand(command) {
                let handler;
                switch (command) {
                    case 'repeat':
                        handler = () => {
                            this.model.clear();
                        };
                        break;
                    case 'back':
                        handler = () => {
                            this.$router.back();
                        };
                        break;
                    default:
                        handler = this.redirectToCard;
                }

                this.save().then(handler);
            },
            async onSaveClick() {
                await this.save();
                if (!this.isEdit) {
                    this.redirectToCard();
                }
            }
        },


        mounted() {
            this.model.on('change', e => {
                this.isChanged = this.model.changed();
            });
        }
    }
</script>


<template>

    <el-form label-position="top">
        <el-row :gutter="20">
            <el-col :span="6" :xs="12">
                <el-form-item label="Иконка"
                              :error="model.errors.icon_cls"
                              :show-message="!!model.errors.icon_cls">
                    <icon-picker v-model="model.icon_cls"></icon-picker>
                </el-form-item>
            </el-col>
            <el-col>
                <el-form-item label="Название"
                              :error="model.errors.name"
                              :show-message="!!model.errors.name"
                              required>
                    <el-input v-model="model.name"
                              placeholder="Мясные изделия"
                              clearable
                              :maxlength="250"></el-input>
                </el-form-item>
            </el-col>
            <el-col>
                <el-form-item label="Алиас (латиницей, без пробелов)"
                              :error="model.errors.alias"
                              :show-message="!!model.errors.alias"
                              required>
                    <el-input v-model="model.alias"
                              placeholder="masnie-izdeliya"
                              clearable=""
                              :maxlength="250"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col>
                <el-form-item label="Краткий текст превью"
                              :error="model.errors.short_text"
                              :show-message="!!model.errors.short_text"
                              required>
                    <el-input type="textarea"
                              v-model="model.short_text"
                              placeholder="Данный текст будет отображаться при просмотре Категории в каталоге"
                              :autosize="{ minRows: 3}"
                              :show-word-limit="true"
                              :maxlength="255"></el-input>
                </el-form-item>
            </el-col>
            <el-col>
                <el-form-item label="Полный текст, можно html"
                              :error="model.errors.full_text"
                              :show-message="!!model.errors.full_text">
                    <el-input type="textarea"
                              v-model="model.full_text"
                              placeholder="Данный текст будет отображаться в карточке Категории. Если текст не задан, вместо него будет использоваться краткий текст превью."
                              :autosize="{ minRows: 5}"></el-input>
                </el-form-item>
            </el-col>
        </el-row>

        <el-button v-if="isEdit"
                   :disabled="!isChanged"
                   v-loading="model.saving"
                   @click="onSaveClick">
            Сохранить
        </el-button>

        <el-dropdown v-if="!isEdit"
                     split-button type="primary"
                     @command="onSaveCommand"
                     v-loading="model.saving"
                     @click="onSaveClick">
            Создать
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="edit">Создать и перейти к редактированию</el-dropdown-item>
                <el-dropdown-item command="back">Создать и вернуться к списку</el-dropdown-item>
                <el-dropdown-item command="repeat">Создать и повторить</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

    </el-form>
</template>


<style lang="scss" scoped>

</style>