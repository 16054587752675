<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import ProductCategory from "../../../models/ProductCategory";

    export default {
        name: "CategoryCard",

        props: {
            id: Number | String
        },


        data() {
            return {
                model: new ProductCategory()
            }
        },


        watch: {
            $route: function (route) {
                // update data only when model`s id changed
                if (route.params.id != this.model.id) {
                    this.prepareData();
                }
            }
        },


        methods: {
            async prepareData() {
                this.model = new ProductCategory({
                    id: this.$route.params.id
                });
                try {
                    await this.model.fetch();
                } catch (e) {
                    this.$message.error("Ошибка загрузки данных.");
                }
            }
        },

        created() {
            // загружаем данные, когда представление создано
            // и данные реактивно отслеживаются
            this.prepareData();
        },
    }
</script>


<template>
    <div v-loading="model.loading"
         class="humanize-container">
        <h1> {{model.$.name}} </h1>

        <div style="padding: 0px 20px 20px 0px">
            <p class="metas">Дата последней модификации: {{model.$.updated_at|datetime}}</p>
            <el-menu :router="true" :default-active="$route.name" mode="horizontal">
                <el-menu-item index="category-basic" :route="{name:'category-basic',params:{model}}">
                    Основное
                </el-menu-item>
                <el-menu-item index="category-images" :route="{name:'category-images', params:{model}}">
                    Картинки
                </el-menu-item>
                <el-menu-item index="category-tree" :route="{name:'category-tree', params:{model}}">
                    Дерево каталога
                </el-menu-item>
            </el-menu>

            <router-view :model="model"></router-view>
        </div>
    </div>
</template>


<style lang="scss" scoped>
    h1 {
        margin-bottom: 0;
    }

    .metas {
        margin-top: 0;
        font-size: smaller;
        color: silver;
    }

    .el-menu {
        margin: 20px 0 30px;
    }
</style>