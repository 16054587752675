<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */


export default {
    name: "IconPicker",
    inheritAttrs: true,
    props: {
        value: null,
        iconsSet: {
            type: Array,
            default: () => [
                'icon-farm icon-cheese',
                'icon-farm icon-chicken',
                'icon-farm icon-chicken-leg',
                'icon-farm icon-chicken-product',
                'icon-farm icon-delivery',
                'icon-farm icon-drinks',
                'icon-farm icon-drinks-alt',
                'icon-farm icon-eggs',
                'icon-farm icon-frozen',
                'icon-farm icon-honey',
                'icon-farm icon-like',
                'icon-farm icon-meat',
                'icon-farm icon-milk',
                'icon-farm icon-ready-meals',
                'icon-farm icon-sausages',
                'icon-farm icon-season',
                'icon-farm icon-ready-meals-2',
                'icon-farm icon-basket',
                'icon-farm icon-duck',
                'icon-farm icon-chicken-2',
                'icon-farm icon-fish',
                'icon-farm icon-sweets',
                'icon-farm icon-mutton',
                'icon-farm icon-winter-season',
                'icon-bottles',
                'icon-meat-and-poultry',
                'icon-rabbit',
                'icon-pet'
            ]
        }
    },

    data() {
        return {
            innerValue: this.value
        }
    },

    watch: {
        innerValue: function () {
            this.$emit("input", this.innerValue);
        }
    }
}
</script>


<template>
    <div>
        <el-select v-model="innerValue" clearable v-bind="$attrs">
            <el-option
                    v-for="(iconCls,index) in iconsSet"
                    :key="index"
                    :label="iconCls"
                    :value="iconCls">
                <el-icon :class="iconCls"></el-icon>
            </el-option>
        </el-select>
    </div>
</template>


<style scoped lang="scss">
@import "../assets/farmfont_20240723/styles.css";

.el-select-dropdown__item {
    display: inline-block;
    font-size: 32px;
    padding: 5px;
    margin: 5px;
}
</style>