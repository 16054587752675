<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import CatalogTree from "../../../components/CatalogTree";
    import ProductCategory from "../../../models/ProductCategory";
    import CategorySelect from "../../../components/CategorySelect";

    export default {
        name: "Tree",
        props: {
            model: {
                type: ProductCategory,
                required: true
            }
        },

        data() {
            return {
                isChanged: false
            }
        },

        components: {
            CategorySelect,
            CatalogTree
        },

        methods: {
            async onSaveClick() {
                this.model.save().then(() => this.$message.success("Данные успешно обновлены"));
            }
        },

        mounted() {
            this.model.on('change', e => {
                this.isChanged = this.model.changed();
            });
        }
    }
</script>

<template>
    <div>
        <el-form>
            <el-form-item label="Родительская категория"
                          :error="model.errors.parent_id"
                          :show-message="!!model.errors.parent_id">
                <category-select :excluded-ids="[model.id, model.parent_id]" v-model="model.parent_id"></category-select>
            </el-form-item>

            <el-form-item :error="model.errors.display_order"
                          label="Порядок отображения"
                          :style="{width:'200px'}"
                          :show-message="!!model.errors.display_order">
                <el-input-number :min="1"
                                 v-model="model.display_order">
                </el-input-number>
            </el-form-item>

            <el-form-item :error="model.errors.is_active"
                          :show-message="!!model.errors.is_active">
                <el-switch active-text="Виден на сайте"
                           v-model="model.is_active"
                           :active-value="1"
                           :inactive-value="0">
                </el-switch>
            </el-form-item>

            <el-button :disabled="!isChanged"
                       v-loading="model.saving"
                       @click="onSaveClick">
                Сохранить
            </el-button>
        </el-form>
    </div>
</template>

<style scoped>

</style>