<script>
    /**
     * Input field to select category
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */

    import BaseStore from "../models/BaseStore";
    import ProductCategory from "../models/ProductCategory";
    import merge from "lodash/merge";


    export default {
        name: "CategorySelect",
        props: {
            multiple: {
                type: Boolean,
                default: false
            },
            value: Array | String,
            excludedIds: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                selectedIds: this.value,
                store: {
                    type: BaseStore,
                    default() {
                        return ProductCategory.getAvailable();
                    }
                }
            }
        },
        methods: {
            fetchAvailableCategories(query) {
                // prevent double request on empty
                if (query === '') {
                    return;
                }

                this.store = ProductCategory.getAvailable(query, [], this.excludedIds);
                this.store.fetch();
            },
            fetchOnValue(value) {
                if (value) {
                    this.store = ProductCategory.getAvailable(null, this.multiple ? value : [value])
                    this.store.fetch();
                }
            }
        },
        watch: {
            value(val) {
                this.selectedIds = val;
                this.fetchOnValue(val);
            },
            selectedIds(selectedIds) {
                this.$emit("input", selectedIds);
            }
        },
        created() {
            this.fetchOnValue(this.selectedIds);
        }
    }
</script>

<template>
    <el-select v-model="selectedIds"
               remote
               :remote-method="fetchAvailableCategories"
               :loading="store.loading"
               clearable
               filterable
               :multiple="multiple"
               placeholder="Название категории...">
        <el-option v-for="model in store.models"
                   :key="model.id"
                   :label="model.name"
                   :value="model.id">
        </el-option>
    </el-select>
</template>

<style scoped>

</style>