<script>
    /**
     * The orders list
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import LazyList from "../../components/LazyList";
    import BaseStore from "../../models/BaseStore";
    import ProductCategory from "../../models/ProductCategory";
    import compact from "lodash/compact";
    import Formatter from "../../utils/Formatter";

    export default {
        name: "List",
        components: {
            LazyList
        },
        data() {
            return {
                store: new BaseStore([], {
                    model: ProductCategory,
                    filters: [
                        // exclude root
                        {
                            property: 'parent_id',
                            operator: 'gt',
                            value: 0
                        }
                    ],
                    fetchParams: {
                        limit: 25,
                        sort: '-updated_at',
                        fields: 'id,name,icon_cls,is_root,root_order,is_active,updated_at',
                        expand: 'thumbUrl'
                    }
                })
            }
        },
        methods: {
            getRoute(record) {
                return {name: 'category-basic', params: {id: record.id}};
            },

            getMetaString(record) {
                return compact([
                    `Посл. изменения: ${Formatter.asDateTime(record.updated_at)}`,
                    !record.is_active && 'Скрыта',
                    record.is_root && '<b>Корневая</b>',
                ]).join(", ");
            },

            onSetVisibleClick(models = []) {
                models.map((model) => {
                    model.is_active = true;
                    model.save()
                        .then(() => {
                            this.$message.success(`Категория ${model.name} активна`);
                        });
                });
            },


            onSetInvisibleClick(models = []) {
                models.map((model) => {
                    model.is_active = false;
                    model.save()
                        .then(() => {
                            this.$message.success(`Категория ${model.name} скрыта`);
                        });
                });
            },


            onDeleteClick(models = []) {
                models.map((model) => {
                    model.delete().then(() => {
                        this.$message.success(`Категория удалена`);
                    });
                });
            },

            onCommand(command, record) {
                switch (command) {
                    case 'show':
                        return this.onSetVisibleClick([record]);
                    case 'hide':
                        return this.onSetInvisibleClick([record]);
                    case 'redirect':
                        return this.$router.push(this.getRoute(record));
                    case 'remove':
                        return this.$confirm(`Уверены что хотите удалить категорию и все ее связи?`, {
                            type: 'error'
                        })
                            .then(() => {
                                this.onDeleteClick([record]);
                            })
                            .catch(() => {
                            });
                }
                console.warn("Unknown command");
            },
        }
    }
</script>


<template>
    <div class="humanize-container--long">
        <lazy-list :store="store"
                   create-route="category-create"
                   details-route="category-basic"
                   tools-width="56"
                   is-checkable
                   is-refreshable
                   filter-placeholder="поиск: Название..."
                   :toolbar-style="{'max-width':'700px'}">

            <template v-slot:toolbar-alternate="{selectedModels}">
                <div class="toolbar-item">Выбрано {{selectedModels.length}} категорий:</div>
                <div class="toolbar-item">
                    <el-tooltip content="Категория и все продукты будут видны на витрине и доступны для заказа покупателем"
                                class="toolbar-item">
                        <el-button type="default"
                                   icon="el-icon-view"
                                   @click="onSetVisibleClick(selectedModels)">Показать
                        </el-button>
                    </el-tooltip>
                </div>
                <div class="toolbar-item">
                    <el-tooltip content="Категория и все продукты будут не видны на витрине и не доступны для заказа покупателем"
                                class="toolbar-item">
                        <el-button @click="onSetInvisibleClick(selectedModels)"
                                   icon="el-icon-document-delete">Скрыть
                        </el-button>
                    </el-tooltip>
                </div>
                <div class="toolbar-item">
                    <el-popconfirm
                            confirmButtonText='Удалить'
                            cancelButtonText='Отмена'
                            confirmButtonType="danger"
                            icon="el-icon-info"
                            iconColor="red"
                            class="toolbar-item"
                            :title="`Будут удалены ${selectedModels.length} категорий и все их связи. Уверены что хотите продолжить?`"
                            @confirm="onDeleteClick(selectedModels)">
                        <el-tooltip slot="reference"
                                    content="Удалить из базы данных">
                            <el-button type="danger"
                                       icon="el-icon-delete"></el-button>
                        </el-tooltip>
                    </el-popconfirm>
                </div>
            </template>

            <template v-slot:item="{record}">
                <div class="list-item"
                     :class="{'is_hidden':!record.is_active, 'is_root':record.is_root}">

                    <div class="thumb">
                        <el-icon v-if="record.icon_cls" class="icon" :class="record.icon_cls"></el-icon>
                        <el-image v-else fit="cover" :src="record.thumbUrl"></el-image>
                    </div>
                    <div class="name text-ellipsis">
                        <router-link :to="getRoute(record)" class="item-name">{{record.name}}</router-link>
                        <div class="metas" v-html="getMetaString(record)"></div>
                    </div>
                </div>
            </template>

            <template v-slot:tools="{record}">
                <el-dropdown trigger="click"
                             @command="onCommand($event, record)">
                    <el-button circle
                               size="medium"
                               icon="el-icon-more"></el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="redirect">Перейти в карточку</el-dropdown-item>
                        <el-dropdown-item command="show">Показать</el-dropdown-item>
                        <el-dropdown-item command="hide">Скрыть</el-dropdown-item>
                        <el-dropdown-item command="remove">Удалить</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </template>
        </lazy-list>
    </div>
</template>

<style scoped lang="scss">
    @import "../../scss/element";

    .list-item {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: space-between;

        &.is_hidden {
            opacity: .5;
        }

        &.is_root {
            .name {
                a {
                    /*font-weight: 700;*/
                }
            }
        }

        > * + * {
            margin-left: 10px;
        }

        .thumb {
            flex-shrink: 0;
            flex-grow: 0;
            width: 40px;

            .icon {
                font-size: 2rem;
            }
        }

        .name {
            flex: 1;
        }
    }

    .toolbar {
        border: 1px solid red;
    }
</style>