<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */

    import ProductCategory from "../models/ProductCategory";
    import Product from "../models/Product";
    import CategorySelect from "./CategorySelect";
    import Header from "../views/AppHeader";

    export default {
        name: "CatalogTree",
        components: {Header, CategorySelect},
        props: {
            rootCategory: {
                type: ProductCategory,
                default() {
                    return new ProductCategory({
                        name: 'Корень каталога'
                    });
                }
            }
        },
        computed: {
            title: vm => vm.rootCategory.name
        },

        data() {
            return {
                treeData: [{
                    label: this.rootCategory.name,
                    children: []
                }],
                props: {
                    label: 'name',
                    isLeaf: 'isLeaf'
                },
                isDialogOpened: false
            }
        },

        methods: {
            loadNode(node, resolve) {
                let data = node && node.data;
                if (data && data.type !== 'category') {
                    return resolve([]);
                }
                let category = data && data.id && new ProductCategory({id: data.id}) || this.rootCategory;
                category.fetchTree()
                    .then(d => resolve(d));
            },
            getNodeIconCls(data) {
                return data.type === 'category' ? (data.icon_cls || 'no-icon') : 'el-icon-goods'
            },
            getNodeCls(data) {
                return data.is_active ? '' : 'hidden'
            },
            handleCommand(command, node) {
                switch (command) {
                    case 'productCard':
                        return this.$router.push({name: 'product-basic', params: {id: node.data.id}});
                    case 'categoryCard':
                        return this.$router.push({name: 'category-basic', params: {id: node.data.id}});
                    case 'hide':
                        let model = node.data.type === 'category' ? new ProductCategory({id: node.data.id}) : new Product({id: node.data.id});
                        model.set({is_active: false});
                        model.save().then(() => node.data.is_active = false);
                        return;
                    case 'changeParent':
                        return this.editParent(node.data);
                }
                console.warn("Unknown command");
            },

            /**
             * @param category Array
             * */
            editParent(category) {
                const h = this.$createElement;
                let parentId;
                this.$msgbox({
                    title: 'Родительская категория',
                    message: h(CategorySelect, {
                        ref: 'select',
                        style: {
                            width: '100%'
                        },
                        props: {
                            "value": category.parent_id,
                            "excluded-ids": [category.parent_id, category.id]
                        },
                        on: {
                            'input': (id) => parentId = id
                        }
                    }, []),
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            if (parentId && parentId !== category.parent_id) {
                                let record = new ProductCategory({id: category.id});
                                record.set({parent_id: parentId});
                                record.save().then(() => done());
                            }
                        } else {
                            done();
                        }
                    }
                }).catch();
            },

            onSubmitClick() {
                this.rootCategory.save();
            }
        }
    }
</script>

<template>
    <div class="tree">
        <h1>{{title}}</h1>
        <el-tree ref="tree"
                 :props="props"
                 lazy
                 :load="loadNode">

            <div class="tree-node"
                 :class="[getNodeCls(data), data.type]"
                 slot-scope="{ node, data }">
                <el-icon class="icon" :class=getNodeIconCls(data)></el-icon>

                <div class="label" v-if="data.type==='category'">{{ node.label }}</div>
                <a class="label" :href="`#/product/${data.id}`" v-else>{{node.label}}</a>

                <!--<div class="tools" v-if="data.type === 'category'">
                    <el-dropdown @command="handleCommand($event, node)"
                                 trigger="click">
                        <span class="el-dropdown-link"
                              @click.stop>
                          действия <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="changeParent" v-if="data.type === 'category'">Сменить родителя</el-dropdown-item>
                            &lt;!&ndash;                            <el-dropdown-item command="hide" v-if="data.is_active">Сделать неактивным</el-dropdown-item>&ndash;&gt;
                            &lt;!&ndash;                            <el-dropdown-item command="show" v-if="!data.is_active">Сделать активным</el-dropdown-item>&ndash;&gt;
                            <el-dropdown-item command="categoryCard" v-if="data.type === 'category'">Перейти в карточку категории</el-dropdown-item>
                            <el-dropdown-item command="productCard" v-if="data.type === 'product'">Перейти в карточку продукта</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>-->
            </div>
        </el-tree>
    </div>
</template>


<style scoped lang="scss">
    @import "../scss/vars";

    h1 {
        font-size: 1.2rem;
        font-weight: 400;
        margin: 0 0 1rem;
        color: $accent-color;
    }

    .tree {
        width: auto;
        max-width: 100%;
        overflow: hidden;
    }

    .tree-node {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: auto;
    }

    .label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
    }

    a.label {
        color: $primary-color;
        font-weight: 300;
    }

    .hidden {
        color: #ccc;
    }

    .category {
        font-weight: bold;

        .icon {
            font-size: x-large;
        }
    }

    .no-icon {
        display: none;
    }

    .icon {
        margin: 0 .5rem 0;
    }

    .tools {
        margin-left: 1rem;
    }

    .el-dropdown-link {
        color: $primary-color;
        font-weight: normal;
    }
</style>