<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */

    import CatalogTree from "../../components/CatalogTree";

    export default {
        name: "ShowcaseTree",
        components: {CatalogTree}
    }
</script>

<template>
    <div class="humanize-container--long">
        <catalog-tree></catalog-tree>
    </div>
</template>


<style scoped lang="scss">

</style>